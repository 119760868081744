import { ROLE_PERMISSIONS } from '@/utils/models/rolePermissions'
import authApi from '@/services/authApi'

export default async function auth({ to, next, router, store}) {
  const token = localStorage.getItem('admin-token')
  if (token) {
    if (to.name === 'auth') {
      return router.push({ name: 'main'})  
    }

    const permissions = to.meta.permissions
    if (to.fullPath.includes('/create')) {
      permissions.action = 'create'
    }

    let role = store.state.userRole
    if (!role) {
      // console.log('fetchin role')
      const { data } = await authApi.getMe()
      store.commit('setAdminUser', data)
      if (data.roles.length > 0) {
        role = data.roles[0]
        store.commit('setUserRole', data.roles[0])
      } else {
        store.commit('setUserRole', 'super_admin')
      }
    }
    // console.log(role, permissions.section, permissions.action)
    // console.log(!checkRolePermission(role, permissions.section, permissions.action))
    if(!checkRolePermission(role, permissions.section, permissions.action)) {
      return router.push({ name: 'main'})  
    }

    
  } else if (to.name !== 'auth' && !token) {
    return router.push({ name: 'auth' })
  }
  return next()
}

function checkRolePermission(role, section, action) {
  if (role === 'super_admin') {
    return true
  }

  const permissionSection = ROLE_PERMISSIONS[section]
  if (!permissionSection) {
    return false
  }
  const permissionAction = permissionSection[action]
  if (!permissionAction) {
    return false
  }

  return permissionAction.includes(role) || permissionAction.includes('*')
}

// const ROUTES = [
//   '/dashboard',
//   '/songs',
//   '/songs/:id',
//   '/ai-generated-songs',
//   '/genres',
//   '/genres/:id',
//   '/artists',
//   '/artists/:id',
//   '/suggested-artists',
//   '/authors',
//   '/authors/:id',
//   '/playlists',
//   '/playlists/:id',
//   '/suggested-playlists',
//   '/albums',
//   '/albums/:id',
//   '/edit-history',
//   '/admins',
//   '/users',
//   '/users/:id',
//   '/statistics',
//   '/marketing-campaigns',
//   '/marketing-campaigns/:id'
// ]