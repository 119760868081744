import axios from 'axios'
import router from '../router'

// httpClient
const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Session-ID': process.env.VUE_APP_SESSION_ID
  }
})

const authHttpClient = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API_URL}/admin`,
  headers: {
    'Content-Type': 'application/json',
    'X-Session-ID': process.env.VUE_APP_SESSION_ID
  }
})
authHttpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('admin-token')
  config.headers.Authorization = 'Bearer ' + token

  return config
})
authHttpClient.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem('admin-token')
    router.push({ name: 'auth' })
    location.reload()
  }
  return error.response
})

const authHttpFileClient = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API_URL}/admin`,
  headers: {
    'X-Session-ID': process.env.VUE_APP_SESSION_ID
  }
})
authHttpFileClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('admin-token')
  config.headers.Authorization = 'Bearer ' + token

  return config
})
authHttpFileClient.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem('admin-token')
    location.reload()
  }
  return error.response
})



export default {
  httpClient,
  authHttpClient,
  authHttpFileClient
}
