<template>
  <div class="global-request-error-modal">
    <div class="grem-title">
      Sorry, occured unconditional error, contact with backend developers
    </div>
    <div v-if="errorObject.error" class="grem-error-text">
      <small>
        error:
      </small>
      {{ errorObject.error }}
    </div>
    <div v-if="errorObject.error_description" class="mt8 grem-error-text"> 
      <small>
        description:
      </small>
      {{ errorObject.error_description }}
    </div>
    <div class="grem-close mt24" @click="closeModal">
      Okey
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalRequestErrorModal',
  props: {
    errorObject: { type: Object, default: null },
    modalName: { type: String, default: 'GlobalRequestErrorModal' }
  },
  methods: {
    closeModal() {
      this.$modal.hide(this.modalName)
    }
  }
}
</script>

<style scoped>
.global-request-error-modal {
  padding: 36px 24px;
  box-sizing: border-box;
  text-align: center;
}
.grem-error-text {
  text-align: left
}
.grem-error-text small {
  font-size: 16px;
  opacity: 0.3;
}
.grem-title {
  font-size: 21px;
  margin-bottom: 24px;
}
.grem-close {
  background-color: var(--gray-5);
  padding: 8px 12px;
  box-sizing: border-box;
  color: var(--white);
  width: 100%;
  margin-top: 24px;
  border-radius: 4px;
  cursor: pointer;
}
</style>