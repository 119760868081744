export const ROLE_PERMISSIONS = {
  dashboard: {
    list: ['*'],
    vputi_subscriptions: ['vputi_partner']
  },
  songs: {
    list: ['partner'],
    create: [],
    read: ['partner'],
    update: [],
    delete: []
  }
}