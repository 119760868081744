import Vue from 'vue'

Vue.filter('genreList', function (genreList) {
  if (genreList.length === 0) {
    return '-'
  }

  const genreArr = []
  for (let genre of genreList) {
    genreArr.push(genre.genre.name)
  }
  
  return genreArr.join(', ')
})