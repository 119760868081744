import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import CardBox from '@/components/components/cards/CardBox.vue'
import LoadingState from '@/components/LoadingState.vue'

import VModal from 'vue-js-modal'
import VueApexCharts from 'vue-apexcharts'

import '@/utils/filters/DateFilter'
import '@/utils/filters/NumberFilter'
import '@/utils/filters/StringFilter'
import mixin from '@/mixin/mixin.js'



Vue.use(VueApexCharts)
Vue.use(VModal)
Vue.mixin(mixin)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('apexchart', VueApexCharts)
Vue.component('card-box', CardBox)
Vue.component('loading-state', LoadingState)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')


// const elems = document.getElementsByClassName("opblock-tag no-desc")
// for(let i = 0; i < elems.length; i++) {
//   elems[i].click()
// }