import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import auth from '@/middleware/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '../pages/LoginPage.vue'),
    meta: {
      middleware: auth
    }
  },
  {
    path: '/',
    name: 'main',
    redirect: 'dashboard',
    component: () => import(/* webpackChunkName: "main" */ '../layouts/AdminLayout.vue'),
    meta: {
      middleware: auth
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../pages/DashboardPage.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'dashboard', action: 'list' }
        },
      },

      // SONG ROUTES
      {
        path: '/songs',
        name: 'Songs',
        component: () => import(/* webpackChunkName: "songs-list" */ '../pages/songs/SongsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'songs', action: 'list' }
        },
      },
      {
        path: '/songs/:id',
        name: 'Song #{id}',
        component: () => import(/* webpackChunkName: "song-view" */ '../pages/songs/SongView.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'songs', action: 'read' }
        },
      },
      {
        path: '/suggested-songs',
        name: 'Suggested Songs',
        component: () => import(/* webpackChunkName: "suggested-songs-list" */ '../pages/songs/SuggestedSongsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'suggested_songs', action: 'list' }
        },
      },
      // SONG ROUTES

      // AI GENERATED SONG ROUTES
      {
        path: '/ai-generated-songs',
        name: 'AI Generated Songs',
        component: () => import(/* webpackChunkName: "ai-generated-songs-list" */ '../pages/aiGeneratedSongs/AIGeneratedSongsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'ai_generated_songs', action: 'list' }
        },
      },
      // AI GENERATED SONG ROUTES

      // GENRES ROUTES
      {
        path: '/genres',
        name: 'Genres',
        component: () => import(/* webpackChunkName: "genres-list" */ '../pages/genres/GenresList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'genres', action: 'list' }
        },
      },
      {
        path: '/genres/:id',
        name: 'Genre #{id}',
        component: () => import(/* webpackChunkName: "genre-view" */ '../pages/genres/GenreView.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'genres', action: 'read' }
        },
      },
      // GENRES ROUTES
      
      // ARTIST ROUTES
      {
        path: '/artists',
        name: 'Artists',
        component: () => import(/* webpackChunkName: "artists-list" */ '../pages/artists/ArtistsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'artists', action: 'list' }
        },
      },
      {
        path: '/artists/:id',
        name: 'Artist #{id}',
        component: () => import(/* webpackChunkName: "artist-view" */ '../pages/artists/ArtistView.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'artists', action: 'read' }
        },
      },
      {
        path: '/suggested-artists',
        name: 'Suggested Artists',
        component: () => import(/* webpackChunkName: "suggested-artists-list" */ '../pages/artists/SuggestedArtistsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'suggested_artists', action: 'list' }
        },
      },
      // ARTIST ROUTES

      // AUTHOR ROUTES
      {
        path: '/authors',
        name: 'Authors',
        component: () => import(/* webpackChunkName: "authors-list" */ '../pages/authors/AuthorsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'authors', action: 'list' }
        },
      },
      {
        path: '/authors/:id',
        name: 'Author #{id}',
        component: () => import(/* webpackChunkName: "artist-view" */ '../pages/authors/AuthorView.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'authors', action: 'read' }
        },
      },
      // AUTHOR ROUTES

      // PLAYLIST ROUTES
      {
        path: '/playlists',
        name: 'Playlists',
        component: () => import(/* webpackChunkName: "playlists-list" */ '../pages/playlists/PlaylistsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'playlists', action: 'list' }
        },
      },
      {
        path: '/playlists/:id',
        name: 'Playlist #{id}',
        component: () => import(/* webpackChunkName: "playlist-view" */ '../pages/playlists/PlaylistView.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'playlists', action: 'read' }
        },
      },
      {
        path: '/suggested-playlists',
        name: 'Suggested Playlists',
        component: () => import(/* webpackChunkName: "suggested-playlists-list" */ '../pages/playlists/SuggestedPlaylistsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'suggested_playlists', action: 'list' }
        },
      },
      // PLAYLIST ROUTES

      // ALBUM ROUTES
      {
        path: '/albums',
        name: 'Albums',
        component: () => import(/* webpackChunkName: "albums-list" */ '../pages/albums/AlbumsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'albums', action: 'list' }
        },
      },
      {
        path: '/albums/:id',
        name: 'Album #{id}',
        component: () => import(/* webpackChunkName: "album-view" */ '../pages/albums/AlbumView.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'albums', action: 'read' }
        },
      },
      // ALBUM ROUTES

      // EDIT HISTORY ROUTES
      {
        path: '/edit-history',
        name: 'Edit History',
        component: () => import(/* webpackChunkName: "edit-history-list" */ '../pages/editHistory/EditHistoryList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'edit_history', action: 'list' }
        },
      },
      // EDIT HISTORY ROUTES


      // ADMIN ROUTES
      {
        path: '/admins',
        name: 'Admins',
        component: () => import(/* webpackChunkName: "admins-list" */ '../pages/admins/AdminsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'admins', action: 'list' }
        },
      },
      // ADMIN ROUTES

      // USERS ROUTES
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users-list" */ '../pages/users/UsersList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'users', action: 'list' }
        },
      },
      {
        path: '/users/:id',
        name: 'User #{id}',
        component: () => import(/* webpackChunkName: "user-view" */ '../pages/users/UserView.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'users', action: 'read' }
        },
      },
      // USERS ROUTES

      // STATISTICS ROUTES
      {
        path: '/statistics',
        name: 'Statistics',
        component: () => import(/* webpackChunkName: "statistics-page" */ '../pages/statistics/StatisticsPage.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'statistics', action: 'list' }
        },
      },
      // STATISTICS ROUTES

      // SETTINGS ROUTES
      {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings-list" */ '../pages/settings/SettingsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'statistics', action: 'list' }
        },
      },
      // SETTINGS ROUTES


      // MARKETING CAMPAIGNS ROUTES
      {
        path: '/marketing-campaigns',
        name: 'Marketing Campaigns',
        component: () => import(/* webpackChunkName: "marketing-campaigns-list" */ '../pages/marketingCampaigns/MarketingCampaignsList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'marketing_campaigns', action: 'list' }
        },
      },
      {
        path: '/marketing-campaigns/:id',
        name: 'Marketing Campaign #{id}',
        component: () => import(/* webpackChunkName: "marketing-campaign-view" */ '../pages/marketingCampaigns/MarketingCampaignView.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'marketing_campaigns', action: 'read' }
        },
      },
      // MARKETING CAMPAIGNS ROUTES


      // PROMO CODES
      {
        path: '/promo-codes',
        name: 'PromoCodes',
        component: () => import(/* webpackChunkName: "promo-codes-list" */ '../pages/promo-codes/PromoCodesList.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'promo-codes', action: 'list' }
        },
      },
      {
        path: '/promo-codes/:id',
        name: 'Promo Code #{id}',
        component: () => import(/* webpackChunkName: "user-view" */ '../pages/promo-codes/PromoCodeView.vue'),
        meta: {
          middleware: auth,
          permissions: { section: 'promo-codes', action: 'read' }
        },
      },
      // PROMO CODES
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next

  return () => {
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  };
}
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
      store
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }
  return next()
})

export default router
