<template>
  <div
    class="card"
    :class="{ 'card-error': cardError }"
  >
    <template v-if="!loading">
      <div v-if="$slots.header" class="card-header">
        <slot name="header">
        </slot>
        <div
          v-if="$slots.body"
          class="card-header-close-btn"
          @click="closeBody = !closeBody"
        >
          <div class="dd-arrow-icon icon-20 gray-4-c-icon" :class="{ 'rotate-icon-180': !closeBody }" />
        </div>
      </div>
      <div v-if="$slots.body && !closeBody" class="card-body">
        <slot name="body">
        </slot>
      </div>
    </template>
    <div v-else class="card-loading-state">
      <div class="loading-icon-css"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardBox',
  props: {
    loading: { type: Boolean, default: false },
    cardError: { type: Boolean, default: false }
  },
  data() {
    return {
      closeBody: false
    }
  }
}
</script>

<style>
.card {
  background-color: var(--white);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  width: 100%;
  border: 1px solid transparent;
  border-radius: 3px;
  /* margin-bottom: 12px; */
}
.card-header {
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  text-decoration: none;
  color: var(--black);
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.card-header a {
  text-decoration: none;
  color: var(--main-color);
  font-size: 15px;
}
.card-body {
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
}
.card-header + .card-body {
  border-top: 1px solid var(--gray-5);
}
.card-header-close-btn {
  cursor: pointer;
  margin-left: 8px;
}

.card-loading-state {
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-error {
  border: 1px solid var(--danger);
}
</style>
