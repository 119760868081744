import CyrillicToTranslit from 'cyrillic-to-translit-js'
import { ROLE_PERMISSIONS } from '@/utils/models/rolePermissions'
import GlobalRequestErrorModal from '@/components/components/modals/GlobalRequestErrorModal.vue'

export default {
  data() {
    return {
      cyrillicToTranslit: null
    }
  },
  mounted() {
    this.cyrillicToTranslit = new CyrillicToTranslit()
  },
  methods: {
    generateSlug(value) {
      value = value.toLowerCase()
      value = value.replaceAll('ғ', 'г')
      value = value.replaceAll('ә', 'а')
      value = value.replaceAll('ғ', 'г')
      value = value.replaceAll('қ', 'к')
      value = value.replaceAll('ң', 'н')
      value = value.replaceAll('ө', 'о')
      value = value.replaceAll('ұ', 'у')
      value = value.replaceAll('ү', 'у')
      value = value.replaceAll('h', 'х')
      value = value.replaceAll('і', 'и')

      const slug = this.cyrillicToTranslit.transform(value, '-')
      return slug
    },
    globalValidateObject(obj, requiredFields) {
      let valid = true
      for (let key in requiredFields) {
        if (obj[key] === '') {
          valid = false
          requiredFields[key] = true
        }
      }
      return valid
    },
    checkRolePermission(section, action) {
      const role = this.$store.state.userRole
      if (role === 'super_admin') {
        return true
      }

      const permissionSection = ROLE_PERMISSIONS[section]
      if (!permissionSection) {
        return false
      }
      const permissionAction = permissionSection[action]
      if (!permissionAction) {
        return false
      }

      return permissionAction.includes(role) || permissionAction.includes('*')
    },
    openGlobalRequestErrorModal(errorObject) {
      this.$modal.show(
        GlobalRequestErrorModal,
        {
          errorObject
        },
        { width: '370px', height: 'auto', name: 'GlobalRequestErrorModal' }
      )
    }

  }
}