import customAPI from './httpClient'


const authLogin = (params) => customAPI.httpClient.post(`/admin/auth/sign-in`, params)

const getMe = () => customAPI.authHttpClient.get('/me')

export default {
  authLogin,
  getMe
}
