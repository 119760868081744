import Vue from 'vue'

Vue.filter('dateFormatter', function (date, options) {
  const defaultOption = { day: '2-digit', month: '2-digit', year: 'numeric' }
  const localDate = new Date(date)

  const tempDate = localDate.toLocaleDateString('ru', options || defaultOption)

  
  return tempDate === 'Invalid Date' ? '-' : tempDate
})