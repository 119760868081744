import Vue from 'vue'

Vue.filter('analyticsCount', function (count) {
  if (!count) {
    return 0
  }
  if (count < 1000) {
    return count
  }
  let returnCount = ''
  const roundNumberItt = [
    { number: 1000, format: 'k', delim: 1000, round: 1 },
    { number: 1000000, format: 'M', delim: 1000000, round: 0 }
  ]
  for (let i = 0; i < roundNumberItt.length; i++) {
    if (count >= roundNumberItt[i].number) {
      returnCount = (Math.round((count / roundNumberItt[i].delim) * 100) / 100).toFixed(roundNumberItt[i].round)
      returnCount = returnCount + roundNumberItt[i].format
      returnCount = returnCount.replace('.', ',')
    }
  }
  return returnCount
})

Vue.filter('songDuration', function (duration) {
  return `${Math.floor(duration / 60)} min ${duration % 60} secs`
})