<template>
  <div
    class="loading-state"
    :style="{
      height: height + 'px'
    }"
  >
    <div class="loading-icon-css" />
  </div>
</template>

<script>
export default {
  name: 'LoadingState',
  props: {
    height: { type: Number, default: 20 }
  }
}
</script>

<style>
.loading-state {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
